.featured-header {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    text-align: center;
    padding: 0px 10px;
}

.filter-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    margin-bottom: 40px;
    font-size: 1.3rem;
    font-weight: bolder;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    max-width: 800px;
    padding: 15px;
}

.price-filter {
    margin: 0px 15px;
}

.filter-select {
    margin-left: 5px;
    padding: 3px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
}

.filter-segment {
    display: flex;
    align-items: center;
}

.filter-dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.no-featured-items-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    text-align: center;
}

.no-featured-items-message {
    margin-top: 0px;
}

.outer-img-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 660px) {
    .filter-bar {
        flex-direction: column;
    }

    .price-filter {
        margin: 10px 0px;
    }
}

.featured-items-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    justify-items: center;
}

@media (min-width: 750px) {
    .featured-items-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1160px) {
    .featured-items-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1440px) {
    .featured-items-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

.back-to-top-button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;
}

.back-to-top-button {
    font-size: 1.8rem;
    background-color: #057cdf;
}