.item-detail-likes {
    max-width: 1000px;
    margin: 0px auto;
    padding: 15px;
}

.like-dislike-container {
    display: flex;
}

.likes {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    margin: 10px 0px;
}

/* .like-button {
    background-color: #1e8513;
} */

.dislike-button {
    /* background-color: #c52434; */
    margin-left: 10px;
}

.switch-vote-button {
    background-color: #c422dd;
    margin-left: 10px;
}

@media screen and (max-width: 805px) {
    .like-button,
    .dislike-button,
    .remove-vote-button,
    .switch-vote-button {
        width: 50%;
    }
}