.cart-panel-container {
    max-width: 1000px;
    margin: 0px auto;
    padding: 15px;
}

.no-scroll {
    overflow: hidden;
    /* opacity: 0.5; */
}

/* .cart-button-container { */
    /* background-color: red; */
    /* display: flex; */
/* } */


.add-remove-cart-buttons {
    display: flex;
    flex-direction: column;
}

.toggle-cart {
    width: 100%;
}

.toggle-cart-signed-out {
    width: 200px;
}

.add-this-item-to-cart-button {
    margin: 10px 0px;
    width: 100%;
}

.remove-this-item-from-cart-button {
    width: 100%;
}

@media screen and (min-width: 805px) {
    .cart-button-container {
        display: flex;
        flex-direction: row;
        width: 600px;
    }

    .add-remove-cart-buttons {
        flex-direction: row;
        width: 100%;
    }

    .smaller-toggle-cart {
        width: 195px;
    }

    .add-this-item-to-cart-button {
        margin: 0px 10px;
    }
}

@media screen and (max-width: 805px) {
    .toggle-cart-signed-out {
        width: 100%;
    }
}

.current-vbucks {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    ;
    font-weight: bold;
}

.gift-message {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    ;
    font-weight: bold;
    color: #cfb56d;
    margin-bottom: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 1;
}