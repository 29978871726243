body {
    font-family: "Luckiest Guy", cursive;
    font-weight: 200;
    color: #ffffff;
    background-color: #0e1220;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

button {
    display: flex;
    font-family: "Luckiest Guy", cursive;
    font-size: 1.2rem;
    background-color: #222222;
    border: 2px solid white;
    color: white;
    transition: 0.5s all;
    cursor: pointer;
    padding: 6px;
    justify-content: center;
    align-items: center;
}

.item-name {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bold;
}

.bolder {
    font-weight: bolder;
}

.loading-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-message {
    font-size: 2rem;
    color: white;
}












/* SUBHEADINGS AND CONTAINERS */
.home-subheading-container {
    display: flex;
    justify-content: center;
}

.home-subheading {
    font-size: 1.8rem;
}

.img-container {
    overflow: hidden;
    /* border: 1px solid white; */
    /* border-right: 2px solid white; */
    /* border-left: 2px solid white; */
    height: 200px;
    width: 200px;
    /* height: 204.8px;
    width: 204.8px; */
    margin-bottom: 10px;
}
















/* BUTTON COLORS */
.blue-button {
    background-color: #0e6ff8;
}

.blue-button:hover {
    background-color: #2138ff;
}

.red-button {
    background-color: #dd2336;
}

.red-button:hover {
    background-color: #b51625;
}

.green-button {
    background-color: #1e8513;
}

.green-button:hover {
    background-color: #137908;
}

.purple-button {
    background-color: #c422dd;
}

.purple-button:hover {
    background-color: #9c1db6;
}

.dark-button {
    background-color: #2e2e2e;
}

.dark-button:hover {
    background-color: #1a1a1a;
}
















/* CAROUSEL CSS */
.carousel-container {
    margin-bottom: 35px;
}


.slick-next {
    right: 15px !important;
}

.slick-prev {
    left: 15px !important;
}

.slick-next,
.slick-prev {
    z-index: 1;
    /* margin-bottom: 60px !important; */
    top: 110px;
}

.slick-prev:before,
.slick-next:before {
    opacity: 1 !important;
}

.slick-list {
    padding: 0 0% !important;
}

.slick-slide {
    display: flex !important;
    justify-content: center !important;
}

/* .slick-track {
    display: flex;
    justify-content: center;
    max-height: 260px !important;
} */

/* .arrow-button {
    cursor: pointer;
} */
















/* ITEM BACKGROUNDS */
.common {
    background: radial-gradient(#bebebe, #646464);
}

.common-container {
    border: 3px solid #b1b1b1;
}

.uncommon {
    background: radial-gradient(#6abb1e, #175117);
}

.uncommon-container {
    border: 3px solid #88e339;
}

.rare {
    background: radial-gradient(#2cc0ff, #143877);
}

.rare-container {
    border: 3px solid #37d0ff;
}

.epic {
    background: radial-gradient(#c359ff, #4c2483);
}

.epic-container {
    border: 3px solid #ea5eff;
}

.legendary {
    background: radial-gradient(#ea8d23, #78371d);
}

.legendary-container {
    border: 3px solid #e98d4b;
}

.dc {
    background: radial-gradient(#5475c7, #243461);
}

.dc-container {
    border: 3px solid #6094ce;
}

.marvel {
    background: radial-gradient(#c53333, #761b1b);
}

.marvel-container {
    border: 3px solid #ef3537;
}

.icon_series {
    background: radial-gradient(#36b7b7, #256b6b);
}

.icon_series-container {
    border: 3px solid #52e0e0;
}

.shadow {
    background: radial-gradient(#717171, #191919);
}

.shadow-container {
    border: 3px solid #949494;
}

.gaming_legends {
    background: radial-gradient(#5447d4, #312497);
}

.gaming_legends-container {
    border: 3px solid #8078ff;
}

.slurp {
    background: radial-gradient(#29f1a3, #12a9a4);
}

.slurp-container {
    border: 3px solid #53f0ff;
}

.frozen {
    background: radial-gradient(#94dfff, #269ed6);
    ;
}

.frozen-container {
    border: 3px solid #c4dff7;
}

.lava {
    background: radial-gradient(#ea8d23, #6a0a31);
}

.lava-container {
    border: 3px solid #d19635;
}

.dark {
    background: radial-gradient(#fb22df, #520c6f);
}

.dark-container {
    border: 3px solid #ff42e7;
}

.star_wars {
    background: radial-gradient(#1b366e, #081737);
}

.star_wars-container {
    border: 3px solid #e7c413;
}