.inventory-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
    padding: 15px;
}

.inventory-heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
}

.inventory-detail-price-heading,
.member-since {
    justify-content: center;
}

.inventory-heading h2 {
    margin: 0px;
}

.inventory-welcome {
    font-family: "Luckiest Guy", cursive;
    margin-top: 20px;
}

.inventory-vbuck {
    margin-left: 10px;
}

.inventory-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
}

/* .inventory-reminders {
    display: flex;
    justify-content: center;
} */

.inventory-item-container {
    display: flex;
    justify-content: center;
}

.inventory-item {
    margin-bottom: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr 0.7fr;
    /* justify-content: center; */
    align-items: center;
    gap: 20px;
    width: 800px;
    font-size: 1.4rem;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bold;
}

.inv-special-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.total-value {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
}

.inventory-subheading {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
    font-family: "Luckiest Guy", cursive;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
}

.unique-items-heading {
    margin-top: 10px;
}

.currently-in-store {
    /* padding: 3px; */
    /* border: 3px solid #ffd148; */
    text-decoration: underline;
    color: #fff5d4;
    text-decoration-color: #ffd148;
    text-decoration-thickness: 5px;
    font-size: 1.8rem;
    /* text-align: center; */
    width: 90%;
}

.inventory-special {
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
}

.inventory-comment-text {
    max-width: 400px;
    text-align: center;
}

.member-since {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.user-creation-date {
    margin-left: 10px !important;
}

@media screen and (max-width: 850px) {
    .inventory-heading {
        text-align: center;
    }

    .img-container {
        margin-bottom: 0px !important;
    }

    .inventory-detail-price {
        justify-content: center;
        margin-bottom: 0px !important;
    }

    .inventory-item {
        display: flex;
        flex-direction: column;
        width: auto;
        /* justify-content: space-between; */
    }

    .inventory-item-info {
        margin: 0px;
        text-align: center;
    }

    .inventory-subheading {
        text-align: center;
    }

    .total-value {
        justify-content: center;
    }

    .member-since {
        justify-content: center;
    }
}

@media screen and (max-width: 420px) {
    .inventory-detail-price-heading {
        flex-direction: column;
    }

    .member-since {
        flex-direction: column;
    }

    .user-creation-date {
        margin-left: 0px;
    }
}