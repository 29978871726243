.search-image,
.search-details {
    cursor: pointer;
}

.search-input {
    /* height: 16px; */
    margin-right: 10px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    padding: 5px;
}

/* .bp-exclusive { */
/* text-align: center; */
/* } */

.search-item-detail-name {
    font-size: 2.0rem;
}

.examples-message {
    margin-bottom: 20px;
    margin-top: 60px;
    font-size: 1.8rem;
}

.popular-searches {
    margin-top: 30px;
    padding: 0px 15px;
}

.popular-searches-list {
    display: flex;
    justify-content: center;
    /* flex-direction: row; */
}

.searches-columns {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    min-width: 335px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    text-align: center;
}

@media screen and (max-width: 900px) {
    .searched-item-container {
        margin-bottom: 330px;
    }
}

@media screen and (max-width: 630px) {
    .searched-item-container {
        margin-bottom: 530px;
    }
}

@media screen and (max-width: 550px) {
    .searched-item-container {
        margin-bottom: 550px;
    }
}