.latest-news-header {
    margin-top: 55px;
}

.news-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    /* margin: 0 auto; */
    padding: 15px;
}

.news-feed {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));  */
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    max-width: 1000px;
}

@media screen and (max-width: 600px) {
    .news-feed {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
    .news-feed {
        grid-template-columns: 1fr 1fr;
    }
}

.latest-news {
    background-color: #ffffff;
    max-width: 500px;
    border: 2px solid white;
}

/* .news-img-container {
    width: 100%;
    height: 20%;
    margin: 0px;
    overflow-x: hidden;
} */

.news-image {
    width: 100%;
    max-width: 500px;
    transition: transform 1s ease;
}

.news-text-heading,
.news-text-body {
    color: #222222;
    padding: 0 10px;
}

.news-text-heading {
    font-size: 1.4rem;
    margin-top: 10px;
}

.news-text-body {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bold;
}

.feed-image-container {
    overflow: hidden;
}

.news-image:hover {
    transform: scale(1.03);
}