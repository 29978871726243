.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 15px auto;
  height: 50px;
  font-size: 1.3rem;
}

.nav-logo-container {
  height: 42px;
  width: 65px;
}

.nav-logo {
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px;
}

.nav-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5rem;
}

.nav-alt-container {
  display: flex;
  align-items: center;
}

.nav-cart {
  font-size: 1.5rem;
  background-color: #0e1220;
  border: none;
  padding: 0px;
}

.nav-cart:hover {
  text-decoration: underline;
}

.active-link {
  color: #0fb8dd;
  text-decoration: underline;
}

.nav-home,
.nav-search,
.nav-about-me,
.nav-cart,
.user-icon {
  margin: 0 5px;
  cursor: pointer;
}

.nav-home:hover,
.nav-about-me:hover,
.nav-view-cart:hover,
.user-icon:hover {
  text-decoration: underline;
}

@media screen and (max-width: 465px) {
  .nav-bar {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0px 10px;
    margin-bottom: 0px;
  }

  .nav-options {
    width: 100%;
    justify-content: space-between;
  }

  .nav-home {
    display: none;
  }

  .nav-home,
  .nav-about-me,
  .nav-view-cart,
  .user-icon {
    margin: 5px;
  }

  .nav-search,
  .nav-cart,
  .nav-home,
  .nav-about-me,
  .nav-inventory {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 388px) {

  .nav-cart,
  .nav-home,
  .nav-about-me,
  .nav-inventory,
  .nav-search {
    font-size: 1.25rem;
  }
}