.item-detail-comments {
    max-width: 1000px;
    margin: 0px auto;
    padding: 15px;
}

.old-comments {
    display: flex;
    /* justify-content: space-between; */
    /* max-width: 500px; */
    height: auto;
    margin-bottom: 15px;
}

.old-comment-content {
    margin-left: 20px;
    flex-grow: 1;
}

.old-comment-content h3 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.comment-profile-image-container {
    height: 75px;
    width: 75px;
    flex-shrink: 0;
}

.comment-profile-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid white;
}

.posted-comments {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
}

.must-be-logged-in {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
}

.edit-comment-button {
    background-color: #c422dd;
}

.delete-comment-button {
    background-color: #c52434;
    margin-left: 10px;
}

.edit-delete-comment-buttons {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 805px) {
    .edit-comment-button {
        width: 50%;
    }

    .delete-comment-button {
        width: 50%;
    }

    .edit-delete-comment-buttons {
        justify-content: space-between;
    }
}

.edit-comment-form {
    margin-bottom: 20px;
}

.edit-comment-form,
.new-comment-form {
    display: flex;
    align-items: center;
}

.comment-input {
    margin-right: 10px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    padding: 5px;
    height: 25px;
}

.submit-comment {
    background-color: #1e8513;
}

.submit-comment:hover {
    background-color: #137908;
}

.submit-comment:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #222222;
}

.cancel-edit {
    background-color: #c52434;
    margin-left: 10px;
}

.edit-comment-container {
    display: flex;
}

.active-edit-delete-comment-buttons {
    display: flex;
}

@media screen and (max-width: 460px) {
    .edit-comment-form {
        flex-direction: column;
        width: 100%;
    }

    .comment-input {
        width: 97%;
        margin-right: 0px;
    }

    .new-comment-input {
        margin-right: 10px;
    }

    .submit-edit {
        width: 50%;
    }

    .cancel-edit {
        width: 50%;
    }

    .active-edit-delete-comment-buttons {
        width: 100%;
        margin-top: 10px;
    }
}