/* .home-container {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.heading-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    text-align: center;
    margin-top: 0px;
}

.hero-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    transition: transform 1s ease;
    /* border: 1px solid white !important; */
    /* border-right: 2px solid white; */
}

.reminder-heading-text {
    margin-bottom: 10px;
}

.home-currently-in-store {
    font-size: 1.6rem;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
}

.home-to-inventory-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    /* width: 900px; */
}

/* @media screen and (min-width: 800px) {
    .home-to-inventory-container {
        width: 900px;
    }
} */

.home-to-inventory {
    font-size: 1.5rem;
}

.hero-banner:hover {
    transform: scale(1.05);
    /* z-index: -1; */
}

.home-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
}

.banner-container {
    overflow: hidden;
    border: 2px solid white;
    max-width: 1000px;
}

.featured-items-button-container {
    display: flex;
    justify-content: center;
    padding: 15px;
}

.featured-items-button {
    font-size: 1.5rem;
}

.home-item-information {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.2rem;
}

.icon-and-price {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-name {
    cursor: pointer;
}

.item-price {
    cursor: pointer;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bold;
}

.vbucks-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-right: 5px;
}

.home-item-image {
    height: 200px;
    width: 200px;
    cursor: pointer;
    transition: transform 1s ease;
    padding: none;
}

.home-item-image:hover {
    transform: scale(1.07);
}

.item-unit {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    max-width: 200px;
    margin: 0 10px;
}

/* .item-unit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */

/* @media screen and (max-width: ) */

@media screen and (max-width: 530px) {
    .home-heading {
        /* padding: 25px; */
    }

    .heading-text {
        font-size: 2.0rem;
    }

    .home-currently-in-store {
        font-size: 1.5rem !important;
    }

    .featured-items-button,
    .home-to-inventory {
        font-size: 1.3rem;
    }
}