.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.login-form-modal {
    background-color: #0e1220;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid white;
    font-size: 1.2rem;
    color: white;
}

.form-input-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.login-form-options {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 50px;
}

@media screen and (max-width: 400px) {
    .login-form-options {
        padding: 20px 20px;
    }
}

.form-input-field {
    margin: 5px 0px;
    padding: 5px;
    border: 1px solid white;
}

.error-message {
    color: rgb(255, 87, 87);
}

.demo-user-button,
.modal-submit-button {
    margin: 10px 0px;
    padding: 10px;
    background-color: #0e6ff8;
}

.demo-user-button:hover,
.modal-submit-button:hover {
    background-color: #2138ff;
}

.modal-submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #222222;
}