.about-me-image {
    /* background-color: #ff5a5f; */
    background: radial-gradient(#ff797d, #ff3e44);
    /* height: 250px; */
    /* width: 250px; */
    height: 100%;
    width: auto;
    border: 3px solid #ff3e44;
}

/* .about-me-image-container {
    height: 100%;
    width: auto;
} */

.about-me-heading {
    display: flex;
    justify-content: space-between;
}

.about-me-rarity {
    background-color: #ff5a5f;
}

.about-me-history {
    width: 230px;
}

.about-me-body-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.about-me-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
}

.body-headers {
    font-size: 1.7rem;
    margin: 0;
}

.about-body-subheading {
    margin: 0;
    color: #cfb56d;
}

.socials {
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    margin-bottom: 50px;
}

.social-logo {
    height: 70px;
    transition: transform 0.75s ease;
}

.other-projects {
    display: flex;
    justify-content: space-between;
}

.socials,
.other-projects {
    margin-top: 30px;
}

.linked-in,
.github,
.serene-haven,
.well-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
}

.project-name {
    font-size: 1.5rem;
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 15px;
}

.project-image {
    border: 2px solid white;
    height: 275px;
    width: auto;
    transition: transform 0.75s ease;
}

.social-logo:hover,
.project-image:hover {
    transform: scale(1.05);
}

.social-links,
.project-links {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    font-weight: bolder;
    font-size: 1.2rem;
}

.separator {
    margin: 0 5px;
}

@media screen and (max-width: 900px) {
    .about-me-body-container {
        margin-top: 350px;
    }
    .about-me-body {
        margin-top: 230px;
        max-width: 500px;
    }

    .about-me-image {
        height: 300px;
        width: 300px;
    }

    .about-me-history {
        margin: 0px;
    }

    /* .small-item-details, */
    .about-me-details {
        margin: 20px auto;
        max-width: 400px;
        text-align: center;
    }

    /* .small-detail-price, */
    /* .small-rarity-section, */
    .about-item-detail-price,
    .about-item-rarity-section {
        display: flex;
        justify-content: center;
    }

    .body-headers {
        display: flex;
        font-size: 1.5rem;
    }

    .about-body-subheading {
        display: flex;
        justify-content: center;
        max-width: 300px;
        text-align: center;
    }

    .other-projects {
        flex-direction: column;
    }

    .well-done {
        margin-top: 30px;
    }

    .serene-haven,
    .well-done {
        margin-right: 0px;
        margin-left: 0px;
    }

    .project-image {
        height: 250px;
        width: auto;
    }
}

@media screen and (max-width: 630px) {
    .about-me-image-container {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
    }

    .about-me-details {
        margin-top: 70px;
    }

    .about-me-body-container {
        margin-top: 0px;
    }

    .about-me-body {
        margin-top: 20px;
    }
}

@media screen and (max-width: 550px) {
    .about-me-body {
        margin-top: 0px;
        max-width: 500px;
    }

    .about-me-image {
        height: 250px;
        width: 250px;
    }

    .small-item-details,
    .about-me-details {
        margin: 20px auto;
        max-width: 400px;
        text-align: center;
    }

    .small-detail-price,
    .small-rarity-section {
        display: flex;
        justify-content: center;
    }

    .body-headers {
        text-align: center;
    }
}