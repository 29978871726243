.item-detail-reminders {
    max-width: 1000px;
    margin: 0px auto;
    padding: 15px;
}

.reminder-message {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
}

.delete-reminder-button {
    background-color: #c52434;
    margin-top: 10px;
}

.reminder-message-logged-out {
    margin-top: 0px;
}

.select-field {
    height: 30px;
    width: 150px;
    margin: 0 10px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    padding: 5px;
}

.reminder-form {
    display: flex;
    align-items: center;
    /* flex-direction: column; */
}

/* .create-reminder {
    background-color: #1e8513;
} */

.delete-reminder-section {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.delete-message {
    display: flex;
    align-items: center;
}

.delete-reminder-button {
    margin-left: 10px;
}

.update-message {
    margin-bottom: 5px;
}

.update-reminder {
    /* margin-top: 10px; */
    background-color: #c422dd;
}

@media screen and (max-width: 805px) {
    .reminder-form {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .update-reminder-label {
        display: flex;
        flex-direction: row;
    }

    .delete-reminder-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .update-reminder {
        width: 100%;
    }

    .delete-reminder-button {
        margin-left: 0px;
        width: 100%;
    }

    .create-reminder {
        margin-top: 10px;
        width: 100%;
        /* background-color: #1e8513; */
    }

    .delete-message {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
}