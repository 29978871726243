.item-detail-heading {
    display: flex;
    margin: 10px auto;
    max-width: 1000px;
    height: 250px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bolder;
    padding: 15px;
}

.item-detail-image-container {
    height: 250px;
    width: auto;
}

.item-detail-image {
    height: 100%;
    width: auto;
}

.item-detail-stats {
    display: flex;
}

.item-history {
    height: 100%;
    max-width: 235px;
    margin-left: 20px;
}

.item-detail-name {
    margin: 0;
}

.item-rarity-section {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.rarity {
    align-items: center;
    padding: 4px 8px;
    margin: 0;
}

.item-type {
    margin: 0 5px;
}

.item-detail-price {
    display: flex;
    align-items: center;
}

/* .item-details { */
/* max-width: 230px; */
/* margin-right: 230px;
    margin-left: 20px; */
/* overflow-y: auto; */
/* } */

.item-details {
    font-weight: bolder;
}

.occurrences {
    display: flex;
    margin: 8px 0 12px;
}

.date-days {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    background-color: #1e223a;
    padding: 0.6rem;
}

.date {
    /* color: #c3b16c; */
    color: #cfb56d;
    margin-right: 5px;
    /* padding-right: 5px; */
}

.days {
    color: #e6e7e8;
    /* margin-left: 5px; */
}

.date-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #1e223a;
    padding: 0.4rem 0.6rem;
    font-weight: bolder;
}

.time-days {
    max-height: 182px;
    height: 80%;
    overflow-y: auto;
    border: 1px solid #1e223a;
}

.loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
}

.idp-subheading {
    margin-bottom: 10px;
}

@media screen and (min-width: 900px) {
    .item-details {
        margin-left: 20px;
    }


    .item-detail-stats {
        width: 100%;
        justify-content: space-between;
    }

    .smaller-toggle-cart {
        width: 195px;
    }
}

@media screen and (max-width: 900px) {
    .item-detail-heading {
        flex-direction: column;
        align-items: center;
        margin-bottom: 300px;
    }

    .item-detail-image-container {
        height: 300px;
        width: 300px;
    }

    .item-detail-image {
        height: 100%;
        width: 100%;
    }

    .item-detail-stats {
        flex-direction: row;
        align-items: center;
        /* justify-content: space-between; */
        margin-top: 20px;
    }

    .item-history {
        margin-left: 25px;
    }
}

@media screen and (max-width: 630px) {
    .item-detail-heading {
        flex-direction: column;
        align-items: center;
        margin-bottom: 540px;
    }

    .exclusive-item-detail-heading {
        margin-bottom: 370px;
    }

    .item-detail-image-container {
        height: 250px;
        width: 250px;
    }

    .item-detail-image {
        height: 100%;
        width: 100%;
    }

    .item-detail-stats {
        flex-direction: column;
        margin-top: 0px;
    }

    .item-details {
        margin: 20px 0;
        text-align: center;
    }

    .small-rarity-section,
    .small-detail-price {
        display: flex;
        justify-content: center;
    }

    .item-history {
        margin-left: 0;
    }

    .occurrences {
        margin-left: 0px;
    }
}