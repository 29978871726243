.cart {
    position: fixed;
    top: 0;
    right: 0;
    width: 350px;
    height: 100%;
    transition: transform 0.4s ease-in-out;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 100;
    background-color: #1e223a;
    padding: 25px;
    font-size: 1.2rem;
}

.cart-content-header {
    margin: 0px;
}

.cart-content {
    padding: 10px;
}

@media screen and (max-width: 500px) {
    .cart {
        font-size: 1.1rem;
    }

    .cart-open {
        padding: 15px;
    }
}

.close-cart-button {
    background-color: red;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    left: 310px;
    margin-bottom: 10px;
    height: 35px;
    width: 35px;
}

.cart-open {
    transform: translateX(0);
}

.cart-closed {
    transform: translateX(100%);
}

.cart-items-heading {
    font-size: 1.5rem;
    margin: 20px auto;
    /* text-align: center; */
}

.cart-item-list {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bold;
    max-width: 400px;
}

.cart-item-container {
    display: flex;
    flex-direction: row;
    max-height: 100%;
    justify-content: space-between;
}

.individual-cart-item {
    margin: 10px 0;
}

.item-detail-price {
    margin: 5px 0;
}

.cart-item-image-container {
    max-height: 100px;
    max-width: 100px;
}

.cart-item-image {
    height: 100%;
    border: 2px solid white;
}

.cart-total-heading {
    margin: 0;
}

.additional-money-message {
    font-family: 'Poppins', calibri, helvetica, arial, sans-serif;
    font-weight: bold;
    color: #cfb56d;
    margin: 40px 0;
}

.starter-cash-message {
    margin: 15px 0px;
}

.current-vbucks {
    margin-bottom: 0;
    margin-top: 0;
}

.inventory-balance-container {
    margin: 15px 0;
}

.inventory-balance {
    /* margin-bottom: 20px; */
    padding: 0px;
}

.balance-amount {
    margin: 0;
}

.in-cart-updates {
    display: flex;
}

.in-cart-add {
    margin-right: 10px;
}

.in-cart-add,
.in-cart-remove {
    border: 2px solid white;
    font-family: "Luckiest Guy", cursive;
    font-size: 1.2rem;
    margin-top: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
}

.checkout-button-container {
    display: flex;
    flex-direction: column;
}

.checkout-button {
    font-size: 1.6rem;
    margin-top: 20px;
}