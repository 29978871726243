.inv-clickable {
    cursor: pointer;
}

.inventory-item-info {
    /* margin: 20px 0px; */
}

.inventory-item-info>div {
    margin-bottom: 10px;
}

.inventory-detail-price {
    display: flex;
    flex-direction: row;
}